/**

* Template Name: Medilab - v2.1.1

* Template URL: https://bootstrapmade.com/medilab-free-medical-bootstrap-theme/

* Author: BootstrapMade.com

* License: https://bootstrapmade.com/license/

*/

$ = jQuery;

!(function($) {

  "use strict";


  $(window).scroll(function() {
    var windowTop = $(window).scrollTop();
    //console.log(windowTop);

  
    if($('.header-menu').length){
      if (windowTop > 45) {
        $('.header-menu').addClass("sticky");
      } else{
        $('.header-menu').removeClass("sticky");
      }

    }

  });

  

})(jQuery);


$(document).ready(function() {

  AOS.init({
    duration: 1500,
  })

  $('li.menu-item-has-children .sub-menu').hide();

  $("li.menu-item-has-children").click(function(e){

    //e.preventDefault();

    if (! $(this).hasClass('act')) {

      $('.menu-mobile li.menu-item-has-children').removeClass('act');

      $('.menu-mobile li.menu-item-has-children .sub-menu').slideUp(300);

      $(this).addClass("act").find('.sub-menu').slideToggle(300);

    }

  });

  $('a[href="#search"]').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').addClass('open');
    $('#fullscreensearch > form > input[type="text"]').focus();
  });
  $('#search-close').on('click', function(event) {
    event.preventDefault();
    $('#fullscreensearch').removeClass('open');
  });

});

// Js pour sidebar menu
const menuMobile =  document.querySelector(".menu-mobile");
function openNav(){
    menuMobile.style.transform = "translate(0,0)";
}
function closeNav(){
    menuMobile.style.transform = "translate(100%,0)";
}
